<template>
  <Teleport
    :to="to"
    v-if="isMounted"
  >
    <slot />
  </Teleport>
</template>

<script>
export default {
  name: 'MountedTeleport',
  props: {
    to: {
      type: [Object, String],
      required: true,
      default() {
        return ''
      }
    }
  },
  data() {
    return {isMounted: false}
  },
  mounted() {
    this.isMounted = true
  }
}
</script>
